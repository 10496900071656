/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_ORDERRQUESTLIST, SET_ORDERRQUESTLIST } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetOrderRequest = function* () {
  yield takeEvery(GET_ORDERRQUESTLIST, workerGetOrderRequest);
};

function* workerGetOrderRequest({ orderId, paymentId }) {
  try {
    const uri =
      apiUrl +
      "cateringorders/order_requestlist?app_id=" +
      appId +
      "&order_id=" +
      orderId +
      "&primary_id=" +
      paymentId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ORDERRQUESTLIST, value: resultArr });
  } catch {
    console.log("Get Order Request Failed");
  }
}
