import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import swal from "sweetalert";
import { format } from "date-fns";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_DELETE_CATERING_CART } from "../../actions";
import {
  appId,
  apiUrlCtrng,
  apiUrl,
  apiUrlV2,
  deliveryId,
  cateringId,
  CountryTxt,
  baseUrl,
} from "../Helpers/Config";
import {
  stripslashes,
  getReferenceID,
  showPriceValue,
  getGstValue,
  getSurchargesplitup,
  showPriceValueNew,
  validateIntval,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";

export class CarteringCart extends Component {
  constructor(props) {
    super(props);
    var zone_id = cookie.load("catering_zone_id")
      ? cookie.load("catering_zone_id")
      : "";
    var outlet_id = cookie.load("orderOutletId")
      ? cookie.load("orderOutletId")
      : "";
    this.state = {
      zone_id: zone_id,
      outlet_id: outlet_id,
      cartDetailsMain: "",
      cartItems: [],
      cartcount: 0,
    };
  }
  componentDidMount() {
    this.loadCartDet();
  }
  componentWillReceiveProps(nextProps) {
    /* Delete Cart Item */
    if (
      nextProps.deleteDetail &&
      this.props.deleteDetail !== nextProps.deleteDetail
    ) {
      if (nextProps.deleteDetail.length > 0) {
        hideLoader("catering_cart_list", "class");
        if (nextProps.deleteDetail[0].status === "ok") {
          swal("Success!", nextProps.deleteDetail[0].message, "success");
          this.loadCartDet();
        } else {
          swal("Error!", nextProps.deleteDetail[0].message, "error");
        }
      }
    }
  }
  loadCartDet() {
    var customerParam = "";
    if (typeof cookie.load("UserId") === "undefined") {
      customerParam = "&reference_id=" + getReferenceID();
    } else {
      customerParam = "&customer_id=" + cookie.load("UserId");
    }
    if (this.state.zone_id !== "") {
      customerParam += "&zone_id=" + this.state.zone_id;
    }
    axios
      .get(apiUrlCtrng + "cart/contents?app_id=" + appId + customerParam)
      .then((res) => {
        let cartDetailsMain = "";
        let cartItems = [];
        let cartcount = 0;
        if (res.data.status === "ok") {
          cartDetailsMain = res.data.result_set.cart_details;
          cartItems = res.data.result_set.cart_items;
          cartcount = res.data.result_set.cart_details.cart_total_items;
          this.setState({
            cartDetailsMain: cartDetailsMain,
            cartItems: cartItems,
            cartcount: cartcount,
          });
        } else {
          this.props.history.push("/catering");
        }
      });
  }
  deleteCartItm(cart_item_id, checkalert) {
    if (checkalert === "alert") {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this item?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (cart_item_id) {
            showLoader("item_" + cart_item_id);
            this.props.getDeleteCateringCart(cart_item_id);
          } else {
            swal("Error!", "You haven’t added any items yet !", "error");
          }
        }
      });
    } else {
      if (cart_item_id) {
        this.props.getDeleteCateringCart(cart_item_id);
      } else {
        swal("Error!", "You haven’t added any items yet !", "error");
      }
    }
  }
  render() {
    return (
      <>
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h1>Cart Summary</h1>
          </div>
        </div>
        <div className="catering-cart">
          <h3 className="catering-cart__header">Cart Summary</h3>
          {this.state.cartItems.length > 0 && (
            <table className="catering-cart__table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th className="textcenter">Quantity</th>
                  <th>Total</th>
                  <th className="textcenter">Remove</th>
                </tr>
              </thead>
              <tbody>
                {this.state.cartItems.map((item, index) => {
                  var cartDate =
                    this.state.cartDetailsMain.cart_catering_order_date !==
                      "" &&
                    this.state.cartDetailsMain.cart_catering_order_date !==
                      "0000-00-00 00:00:00"
                      ? this.state.cartDetailsMain.cart_catering_order_date
                      : "";
                  var splitDate = cartDate !== "" ? cartDate.split(" ") : "";
                  var startTime =
                    splitDate !== "" && item.cart_item_breaktime_started !== ""
                      ? format(
                          new Date(
                            splitDate[0] +
                              " " +
                              item.cart_item_breaktime_started +
                              ":00"
                          ),
                          "hh:mm a"
                        )
                      : "";
                  var endTime =
                    splitDate !== "" && item.cart_item_breaktime_ended !== ""
                      ? format(
                          new Date(
                            splitDate[0] +
                              " " +
                              item.cart_item_breaktime_ended +
                              ":00"
                          ),
                          "hh:mm a"
                        )
                      : "";
                  var cartDisplayDate =
                    cartDate !== ""
                      ? format(
                          new Date(
                            this.state.cartDetailsMain.cart_catering_order_date
                          ),
                          "MM/dd/yyyy"
                        )
                      : "";

                  return (
                    <tr
                      key={index}
                      id={"item_" + item.cart_item_id}
                      className="catering_cart_list"
                    >
                      <td>
                        <div className="catering-cart__cell-inner">
                          <span className="catering-cart__item-name">
                            {item.cart_item_product_sku}
                          </span>
                          <span>Delivery Date: {cartDisplayDate}</span>
                          <span>
                            Delivery Time: {startTime} - {endTime}
                          </span>
                        </div>
                      </td>
                      <td>${item.cart_item_unit_price}</td>
                      <td className="textcenter">
                        <span className="counter cart-counter qty_bx">
                          <strong className="product_quantity">
                            {item.cart_item_qty}
                          </strong>
                        </span>
                      </td>
                      <td>${item.cart_item_total_price}</td>
                      <td className="textcenter">
                        <Link
                          onClick={this.deleteCartItm.bind(
                            this,
                            item.cart_item_id,
                            "alert"
                          )}
                          title="Delete"
                          to="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="close-icon"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
                {this.state.cartDetailsMain !== "" && (
                  <tr className="catering-cart__last-row">
                    <td>Subtotal</td>
                    <td></td>
                    <td></td>
                    <td>${this.state.cartDetailsMain.cart_sub_total}</td>
                    <td className="textcenter catering-cart__table-action">
                      <Link to="/catering-checkout">
                        <button className="proceed-btn">Proceed</button>
                      </Link>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeleteCateringCart: (cartItemId) => {
      dispatch({ type: GET_DELETE_CATERING_CART, cartItemId });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    deleteDetail: state.deleteCateringCart,
  };
};

CarteringCart = connect(mapStateToProps, mapDispatchToProps)(CarteringCart);

export default withRouter(CarteringCart);
