import React, { Component } from "react"
import Footer from "../Layout/Footer"
import Header from "../Layout/Header"
import { Link } from "react-router-dom"

export class Checkout extends Component {
  state = {
    type: "delivery", // "pickup"
  }

  render() {
    const { type } = this.state

    return (
      <>
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h1>Catering Checkout</h1>
          </div>
        </div>
        <div className="catering-checkout container">
          <Link to="/catering-cart" className="catering-checkout__back-btn">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="catering-checkout__back">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Back to Cart
          </Link>
          <h3 className="catering-checkout__header">Checkout</h3>
          {type === "delivery" && (
            <div className="catering-checkout__address">
              <h5>Delivery Address</h5>
              <div className="form-group catering-checkout__form-group catering-checkout__form-group--half">
                <div className="catering-checkout__input-group">
                  <label htmlFor="">First Name</label>
                  <input type="text" />
                </div>
                <div className="catering-checkout__input-group">
                  <label htmlFor="">Last Name</label>
                  <input type="text" />
                </div>
              </div>
              <div className="form-group catering-checkout__form-group">
                <div className="catering-checkout__input-group">
                  <label htmlFor="">Postal Code</label>
                  <input type="text" />
                </div>
              </div>
              <div className="form-group catering-checkout__form-group">
                <div className="catering-checkout__input-group">
                  <label htmlFor="">Street Address</label>
                  <input type="text" />
                </div>
              </div>
              <div className="form-group catering-checkout__form-group catering-checkout__form-group--half">
                <div className="catering-checkout__input-group">
                  <label htmlFor="">House Number</label>
                  <input type="text" />
                </div>
                <div className="catering-checkout__input-group">
                  <label htmlFor="">Area</label>
                  <input type="text" />
                </div>
              </div>
            </div>
          )}
          {type === "pickup" && (
            <div>
              <div className="catering-checkout__summary-item">
                <span className="catering-checkout__summary-label">Pickup Date:</span>
                <span className="catering-checkout__summary-value">15 Aug, Monday</span>
              </div>
              <div className="catering-checkout__summary-item">
                <span className="catering-checkout__summary-label">Pickup Time: </span>
                <span className="catering-checkout__summary-value">01:00pm - 02:00pm</span>
              </div>
              <div className="catering-checkout__summary-item">
                <span className="catering-checkout__summary-label">Pickup Address:</span>
                <span className="catering-checkout__summary-value">Makisan HQ</span>
              </div>
            </div>
          )}
          <h3 className="catering-checkout__header">Order Summary</h3>
          <div className="catering-checkout__order-summary">
            <div className="catering-checkout__summary-item">
              <span className="catering-checkout__summary-label">Products (2):</span>
              <span className="catering-checkout__summary-value">$200</span>
            </div>
            <div className="catering-checkout__summary-item">
              <span className="catering-checkout__summary-label">Delivery Fees:</span>
              <span className="catering-checkout__summary-value">TBD</span>
            </div>
          </div>
          <p className="catering-checkout__note">The price above excludes GST. The final figure and delivery fees will be sent to you through email.</p>
          <div className="catering-checkout__agree-tac">
            <input type="checkbox" />I agree to the <a href="#">Terms and Conditions</a>.
          </div>
          <Link to="/catering-checkout-success">
            <button className="catering-checkout__send-btn">Send Order</button>
          </Link>
        </div>
        <Footer />
      </>
    )
  }
}

export default Checkout
