/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from "moment";
import {
  getReferenceID,
  stripslashes,
  getCalculatedAmount,
  callImage,
  showLoader,
  hideLoader,
  getOrderDateTime,
  getPromoCkValue,
  removeOrderDateTime,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import {
  appId,
  languageCd,
  apiUrl,
  deliveryId,
  cateringId,
  CountryTxt,
  clientCurrency,
} from "../Helpers/Config";
import productImg from "../../common/images/noimg-400x400.jpg";
import scootyImg from "../../common/images/scooty.png";
import pickupImg from "../../common/images/takeaway_old-b.png";
import cartMpImg from "../../common/images/cart-map.png";

import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_CATERINGCART_DETAIL,
} from "../../actions";

const lang = require("../Helpers/Language/lang" + "-" + languageCd);
var Parser = require("html-react-parser");

class CartSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      cateringCartItems: [],
      cateringCartDetails: [],
      cateringCartTotalItmCount: 0,
    };
  }

  componentWillMount() {
    var avltyTxt = cookie.load("defaultAvilablityId");
    if (avltyTxt === cateringId) {
      this.props.getCateringCartDetail();
    } else {
      this.props.getCartDetail();
    }
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartflg", "no");
      this.props.getCartDetail();
    }
    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");
  }

  componentDidMount() {}

  removePromoFun() {
    removePromoCkValue();
    this.props.getCartDetail();
  }

  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var zoneDetails = this.props.zonedetails;

      /*var calculatedAmount = getCalculatedAmount(globalSettings,cartDetailsArr,promoTionArr);*/

      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr
      );

      var orderDateTime =
        typeof cookie.load("orderDateTime") === "undefined"
          ? ""
          : cookie.load("orderDateTime");
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime(orderDateTime, orderTAT);

      return (
        <div className="hcart_dropdown">
          <div className="hcart_tt">
            <div className="row-replace">
              <div className="col-sm-cls text-left">
                <h3>{lang.cartlist.title}</h3>
              </div>
              <div className="cart-close-icon">
                <i className="fa fa-times" data-unicode="f00d"></i>
              </div>
            </div>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_table">
              <div className="cart_body">
                {cookie.load("defaultAvilablityId") === deliveryId ? (
                  <div className="delivery-cart-div">
                    {/*<div className="cart-direction">
								  <img className="cart-direction-left" src={scootyImg} />
								  <img className="cart-direction-right" src={cartMpImg} />
								   </div>*/}
                    <div className="cart_row cart-header-first">
                      <div className="row-replace">
                        <div className="col-sm-cls cart_left text-left">
                          <h4>{lang.cartlist.orderhandl}</h4>
                          <p>{cookie.load("orderOutletName")}</p>
                          <p>{cookie.load("orderHandledByText")}</p>
                        </div>
                        <div className="col-sm-cls cart_right text-right">
                          <h4>{lang.cartlist.deliverylocation}</h4>
                          <p>{cookie.load("orderDeliveryAddress")}</p>
                          <p>
                            {CountryTxt} {cookie.load("orderPostalCode")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pickup-cart-div">
                    {/*<div className="cart-direction">
								  <img className="cart-direction-left" src={pickupImg} />
								</div>*/}
                    <div className="cart_row cart-header-first">
                      <div className="row-replace">
                        <div className="col-xs-12 cart_left text-center">
                          <h4>{lang.cartlist.pickuplocation}</h4>
                          <p>{cookie.load("orderOutletName")}</p>
                          <p>{cookie.load("orderHandledByText")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="cart_row cart-header-second">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <h5>
                        {cookie.load("defaultAvilablityId") === deliveryId
                          ? lang.cartlist.deliverydate
                          : lang.cartlist.pickupdate}
                      </h5>
                      <h3>{moment(orderDateTmTxt).format("DD/MM/YYYY")}</h3>
                    </div>
                    <div className="col-sm-cls text-right">
                      <h5>
                        {cookie.load("defaultAvilablityId") === deliveryId
                          ? lang.cartlist.deliverytime
                          : lang.cartlist.pickuptime}
                      </h5>
                      <h3>{moment(orderDateTmTxt).format("hh:mm A")}</h3>
                    </div>
                  </div>
                </div>
                <div className="hcart_tt">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <h3>{lang.cartlist.subtitle}</h3>
                    </div>
                    <div className="col-sm-cls text-right">
                      <a
                        href="javascript:void(0)"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title={lang.cartlist.cartclear}
                      >
                        {lang.cartlist.cartclear}
                      </a>
                    </div>
                  </div>
                </div>

                {this.cartItemList()}
              </div>

              <div className="cart_footer">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">
                        {lang.cartlist.subtotalLbl}
                      </p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        {clientCurrency}
                        {calculatedAmount["cartSubTotalAmount"]}
                      </span>
                    </div>
                  </div>
                </div>
                {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {lang.cartlist.deliveryLbl}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {clientCurrency}
                          {parseFloat(
                            calculatedAmount["deliveryCharge"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {lang.cartlist.additional_deliveryLbl}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {clientCurrency}
                          {parseFloat(
                            calculatedAmount["additionalDelivery"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {lang.cartlist.promo_code}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {clientCurrency}
                          {parseFloat(
                            calculatedAmount["promotionAmount"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <a
                      href="javascript:void(0)"
                      onClick={this.removePromoFun.bind(this)}
                      className="cart_remove"
                    ></a>
                  </div>
                )}
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {lang.cartlist.gstLbl} (
                          {calculatedAmount["orderDisplayGst"]} %)
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {clientCurrency}
                          {parseFloat(
                            calculatedAmount["orderGstAmount"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="cart_row grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">{lang.cartlist.totalLbl}</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        <sup>{clientCurrency}</sup>
                        {calculatedAmount["grandTotalAmount"]}
                      </span>
                    </div>
                  </div>
                  {calculatedAmount["orderGstInclusive"] > 0 && (
                    <p className="gst-inclusive-lbl">
                      {calculatedAmount["orderGstInclusiveAmount"]}
                    </p>
                  )}
                </div>

                {cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadDeliveryPercentage()}
              </div>
            </div>
          </div>
          <div className="cart_action">
            <div className="cartaction_bottom">
              <Link
                to={"/checkout"}
                className="btn btn_pink cartaction_checkout"
                title={lang.cartlist.checkout}
              >
                {lang.cartlist.checkout}
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {clientCurrency}
              {remainAmnt} more to FREE delivery!
            </p>
          </div>
        </div>
      );
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="row-replace">
            <div className="col-sm-cls cart_left">
              <div className="cart_img">
                <a href="javascript:void(0)" title="">
                  {product.cart_item_product_image !== "" ? (
                    <img
                      src={product.cart_item_product_image}
                      width="110"
                      height="67"
                      alt=""
                    />
                  ) : (
                    <img src={productImg} width="110" height="67" alt="" />
                  )}
                </a>
              </div>
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>

                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="help-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.updateCartQty.bind(this, product, "decr")}
                >
                  -
                </span>
                <input type="text" value={product.cart_item_qty} readOnly />
                <span
                  className="qty_plus"
                  onClick={this.updateCartQty.bind(this, product, "incr")}
                >
                  +
                </span>
              </div>
              <div className="cart_price">
                <p>
                  {clientCurrency}
                  {product.cart_item_total_price}
                </p>
              </div>
            </div>
          </div>
          <a
            href="javascript:void(0)"
            onClick={this.deleteCartItm.bind(this, product)}
            className="cart_remove"
          ></a>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div className="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          modName +
          ":</b></p> <p>" +
          modval +
          newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(productId, cartItemId, cartQty);
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(productId, cartItemId, cartQty);
    }
    removePromoCkValue();
  }

  deleteCartItm(itemArr) {
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm() {
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }

  gotoCateringChkut() {
    var cartTotal = this.props.cateringCartTotalItmCount;
    /*if(parseFloat(cartTotal)>0) {*/
    cookie.save("cateringCheckoutFromCart", "Yes", { path: "/" });
    this.props.history.push("/catering-cart");
    /*}*/
  }

  render() {
    var currenturl = window.location.href;
    var substring1 = "checkout";

    var avltyTxt = cookie.load("defaultAvilablityId");

    return (
      <li className="htico_cart">
        {avltyTxt === cateringId ? (
          <a
            href="javascript:void(0);"
            onClick={this.gotoCateringChkut.bind(this)}
            className="hcartdd_trigger"
            title=""
          >
            <i></i>
            <span className="hcart_round">
              {this.props.cateringCartTotalItmCount}
            </span>
          </a>
        ) : (
          <a href="javascript:void(0);" className="hcartdd_trigger" title="">
            <i></i>
            <span className="hcart_round">{this.props.cartTotalItmCount}</span>
          </a>
        )}
        {currenturl.includes(substring1) !== true && avltyTxt !== cateringId
          ? this.cartDetailsList()
          : ""}
      </li>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  cookie.save("cartTotalItems", cartTotalItmCount, { path: "/" });

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty) => {
      dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(CartSideBar);
