import { takeEvery, call, put } from "redux-saga/effects";
import { GET_GLOBAL_SETTINGS, SET_GLOBAL_SETTINGS } from "../actions";
import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
} from "../components/Helpers/Config";
import cookie from "react-cookies";
import Axios from "axios";

export const watchGetSettings = function* () {
  yield takeEvery(GET_GLOBAL_SETTINGS, workerGetSettings);
};

function* workerGetSettings() {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") === ""
        ? ""
        : cookie.load("defaultAvilablityId");
    var orderPostalCode =
      cookie.load("orderPostalCode") === undefined ||
      cookie.load("orderPostalCode") === ""
        ? ""
        : cookie.load("orderPostalCode");
    var posCdParm =
      orderPostalCode !== "" ? "&postal_code=" + orderPostalCode : "";
    var avltyParm =
      availabilityId !== "" ? "&availability=" + availabilityId : "";

    const uri =
      apiUrl +
      "settings/get_common_settings?app_id=" +
      appId +
      avltyParm +
      posCdParm;
    const result = yield call(Axios.get, uri);
    cookie.save("deliveryOption", "No", { path: "/" });
    cookie.save("pickupOption", "No", { path: "/" });
    cookie.save("cateringOption", "No", { path: "/" });
    cookie.save("reservationOption", "No", { path: "/" });
    if (result.data.status === "ok") {
      var resultSet = result.data.result_set;
      /*  availabilityId =
        availabilityId !== ""
          ? availabilityId
          : resultSet.ninja_pro_default_availability_id; */
      var availability = resultSet.availability;
      var availabilityLen = availability.length;
      for (var i = 0; i < availabilityLen; i++) {
        if (availability[i].availability_id === deliveryId) {
          cookie.save("deliveryOption", "Yes", { path: "/" });
        }
        if (availability[i].availability_id === pickupId) {
          cookie.save("pickupOption", "Yes", { path: "/" });
        }
        if (availability[i].availability_id === cateringId) {
          cookie.save("cateringOption", "Yes", { path: "/" });
        }
        if (availability[i].availability_id === reservationId) {
          cookie.save("reservationOption", "Yes", { path: "/" });
        }
      }
    }
    availabilityId = availabilityId !== "" ? availabilityId : deliveryId;
    console.log(availabilityId, "availabilityIdavailabilityIdavailabilityId");
    cookie.save("defaultAvilablityId", availabilityId, { path: "/" });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_GLOBAL_SETTINGS, value: resultArr });
  } catch {
    console.log("Get Settings Failed");
  }
}
