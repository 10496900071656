import React, { Component } from "react"
import { Link } from "react-router-dom"
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

export class CheckoutSuccess extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h1>Checkout Success</h1>
          </div>
        </div>
        <div className="container catering-success">
          <h3 className="catering-success__header">Order Successfully Sent!</h3>
          <p className="catering-success__info">Further details of your order will be sent to your email.</p>
          <Link to="/catering">
            <button className="catering-success__back-btn">Back to Home</button>
          </Link>
        </div>
        <Footer />
      </>
    )
  }
}

export default CheckoutSuccess
