/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import {
  appId,
  languageCd,
  deliveryId,
  pickupId,
  apiUrlV2,
} from "../Helpers/Config";
import { showLoader, hideLoader } from "../Helpers/SettingHelper";

class Qrcode extends Component {
  constructor(props) {
    super(props);

    let slugtext =
      typeof this.props.match.params.slugtext !== "undefined"
        ? this.props.match.params.slugtext
        : "";
    cookie.remove("qrcodeScannerAction", { path: "/" });
    cookie.remove("qrcodeOutlet", { path: "/" });
    cookie.remove("qrcodeScannerError", { path: "/" });
    localStorage.removeItem("qrcodeScannerAction");
    localStorage.removeItem("qrcodeOutlet");
    localStorage.removeItem("qrcodeOutletData");
    localStorage.removeItem("qrcodeScannerError");

    if (slugtext !== "") {
      var slugtextArr = slugtext.split("-");
      var qrcodeOutletId = slugtextArr[1];

      showLoader("qrcode-main-div", "class");
      if (qrcodeOutletId !== "" && qrcodeOutletId !== undefined) {
        axios
          .get(
            apiUrlV2 +
              "outlets/getAllOutles?app_id=" +
              appId +
              "&lang_code=" +
              languageCd +
              "&availability_id=" +
              pickupId +
              "&outlet_id=" +
              qrcodeOutletId
          )
          .then((res) => {
            hideLoader("qrcode-main-div", "class");
            var errorInfSt = "";
            /* Success response */
            if (res.data.status === "ok") {
              if (Object.keys(res.data.result_set).length > 0) {
                localStorage.setItem("qrcodeScannerAction", "yes");
                cookie.save("qrcodeScannerAction", "yes", { path: "/" });
                localStorage.setItem("qrcodeScannerError", "no");
                cookie.save("qrcodeScannerError", "no", { path: "/" });
                localStorage.setItem("qrcodeOutlet", qrcodeOutletId);
                cookie.save("qrcodeOutlet", qrcodeOutletId, { path: "/" });
                localStorage.setItem(
                  "qrcodeOutletData",
                  JSON.stringify(res.data.result_set)
                );

                setTimeout(function () {
                  window.location.replace("/");
                }, 100);
              } else {
                errorInfSt = "yes";
              }
            } else {
              errorInfSt = "yes";
            }

            if (errorInfSt === "yes") {
              localStorage.setItem("qrcodeScannerError", "yes");
              cookie.save("qrcodeScannerError", "yes", { path: "/" });
              cookie.remove("qrcodeScannerAction", { path: "/" });
              cookie.remove("qrcodeOutlet", { path: "/" });
              localStorage.removeItem("qrcodeScannerAction");
              localStorage.removeItem("qrcodeOutlet");
              localStorage.removeItem("qrcodeOutletData");
              this.props.history.push("/");
            }
          });
      }
    } else {
      cookie.remove("qrcodeScannerAction", { path: "/" });
      cookie.remove("qrcodeOutlet", { path: "/" });
      cookie.remove("qrcodeScannerError", { path: "/" });
      localStorage.removeItem("qrcodeScannerAction");
      localStorage.removeItem("qrcodeOutlet");
      localStorage.removeItem("qrcodeOutletData");
      localStorage.removeItem("qrcodeScannerError");
      this.props.history.push("/");
    }

    /*this.props.history.push('/');*/
  }

  render() {
    return <div className="qrcode-main-div"> Loading...</div>;
  }
}

export default Qrcode;
