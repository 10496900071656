/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CATERINGPORDER, SET_CATERINGPORDER } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCateringPast = function* () {
  yield takeEvery(GET_CATERINGPORDER, workerGetCateringPast);
};

function* workerGetCateringPast({ cateringpastparams }) {
  try {
    const uri =
      apiUrl +
      "reports/order_history?order_status=P&app_id=" +
      appId +
      cateringpastparams;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CATERINGPORDER, value: resultArr });
  } catch {
    console.log("Get CateringPast order Failed");
  }
}
