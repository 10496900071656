var todayTimeSp = new Date();
var yearSp = todayTimeSp.getFullYear();

module.exports = {
  metadata: {
    home_title: "欢迎光临",
    home_desc: "欢迎光临",
    home_name_key: "欢迎光临",
  },

  /* Home page navigation items */
  navigation: {
    login: "登陆",
    signup: "登入",
    register: "登记",
    myaccount: "我的账户",
    logout: "离开（登出）",
    changepassword: "更改密码",
    searchboxlbl: "查找...",
    searchnoresult: "没找到产品",
    orderNow: "下订单",
  },

  /* Face book  */
  fb: {
    fbURL: "",
    fbImage: "",
    fbTitle: "",
    fbDesc: "",
  },

  /* Twitter  */
  tw: {
    twURL: "",
    twTitile: "",
    twvia: "",
  },

  /* google Plus data   */
  gp: {
    gpURL: "",
  },

  countrypopup: {
    selected: "新加坡",
    /*selected: '印度尼西亚',*/
    title: "输入您的地点",
    countrylable1: "新加坡",
    countrylable2: "印度尼西亚",
  },

  availability: {
    delivery: "送货",
    takeaway: "自取",
    catering: "餐饮业",
    reservation: "保留",
  },

  availabilitypopup: {
    title: "下订单",
    subtitle: "选择您的订单类型",
    delivery: "送货",
    takeaway: "自取",
  },

  deliverypopup: {
    title: "通知我们",
    subtitle: "您的收货地点",
    postalcodelbl: "请输入您的邮编",
    newaddresslbl: "输入新的地址",
    postalcodeerror: "请输入有效邮编号码",
    backbtn: "返回",
    continuebtn: "继续",
  },

  deliveryerrorpopup: {
    title: "抱歉",
    subtitle: "邮编无效（抱歉，您的邮编输入无效）",
    postalcodelbl: "精彩的",
    postalcodeerror: "请输入有效邮编号码",
    addresserror_lbl1: "我们不能马上交货！",
    addresserror_lbl2: "请再回来一次。",
    change_address: "更换地址",
    change_outlet: "更换网点",
    continuebtn: "继续",
  },

  deliverysuccesspopup: {
    title: "精彩的",
    subtitle: "我们提供送货服务 !",
    deliveryaddresslbl: "您的收货地址",
    pickupaddresslbl: "您的自取货物地点",
    deliverydateselectlbl: "选择收货的日期和时间",
    pickupdateselectlbl: "选择自取货物的日期和时间",
    continuebtn: "继续",
  },

  takeawaypopup: {
    title: "请选择",
    subtitle: "您自取货物的网点",
    delvry_subtitle: "您选择送货的网点",
    searchoutletlbl: "查询网点",
    searchoutleterror: "请选择网点",
    searchoutleterror_map: "请搜索并选择一个出口。",
    innerlbl: "最近的网点",
    nooutletfnd: "没有有效的网点",
    continuebtn: "继续",
  },

  warningpopup: {
    title: "警告",
    subtitle: "清空您的购物车",
    subtitle_lang: "所有现有更改（包括购物车中的物品）将被清除。",
    description: "您要继续吗？",
    nobtn: "否",
    yesbtn: "是",
  },

  loginpopup: {
    title: "您有账号吗？",
    subtitle: "更新您的登陆详细信息",
    fbnametext: "使用脸书登陆",
    guestloginbtn: "我想使用游客身份结账",
    separator: "或者",
    innerlbl: "使用xx登陆",
    emaillabel: "电子邮箱地址",
    passwordlabel: "密码",
    isemptyerror: "必填",
    validemail: "无效电子邮件",
    createaccountlink: "创建账户",
    forgotpwdlink: "忘记密码",
    submitbtn: "提交",
    successmsg: "成功登陆",
    errormsg: "无效登陆资格",
  },

  signuppopup: {
    title: "新手 Makisan ?",
    subtitle: "创建您的 Makisan 账号.",
    fbnametext: "使用脸书登陆",
    backbtn: "返回",
    separator: "或者",
    innerlbl: "使用xx创建账号",
    infolabel: "您的通知",
    firstnamelabel: "名",
    lastnamelabel: "姓",
    mobilenamelabel: "手机号码",
    loginlabel: "您的登陆名",
    emaillabel: "电子邮箱地址",
    passwordlabel: "密码",
    confirmpwdlabel: "确认密码",
    isemptyerror: "必填",
    validemail: "无效电子邮件",
    alreadymail: "电子邮件已存在",
    validemobile: "您确认有效手机号码",
    alreadymobile: "手机号码已存在",
    validepassword: "密码必须至少6个字符",
    valideconfirmpwd: "密码无法配对",
    submitbtn: "提交",
    successmsg: "信息已发送",
  },

  guestpopup: {
    title: "新手 Makisan ?",
    subtitle: "创建您的 Makisan 账号.",
    backbtn: "返回",
    innerlbl: "客户结账详情",
    mobilelabel: "手提号码",
    namelabel: "姓名",
    emaillabel: "电子邮件",
    isemptyerror: "必填",
    validemail: "无效电子邮件",
    validemobile: "请确认有效的电话号码",
    submitbtn: "提交",
  },

  forgotpasswordpopup: {
    title: "忘记密码？",
    subtitle: "您可以在此重新设置您的密码",
    backbtn: "返回登陆页面",
    emaillabel: "电子邮箱地址",
    isemptyerror: "必填",
    validemail: "无效电子邮件",
    submitbtn: "获得重新设置链接",
  },

  resetpasswordpopup: {
    title: "重新设置密码",
    newpasswordlabel: "新密码",
    confirmpasswordlabel: "确认密码",
    newpassworderror: "需要新密码",
    confirmpasserror: "需要确认新密码",
    submitbtn: "提交",
  },

  homebannerinfo: {
    title: "原版自包寿司和沙拉种类",
    subtitle: "现在在新加坡和印度尼西亚。更多国家陆续登陆。",
    orderNow: "下订单",
  },

  product: {
    ordernowbtn: "现在点菜",
    soldoutbtn: "沽清",
    addtocartbtn: "加入购物差",
    donebtn: "完成",
    specialremark_placehldr: "请在此输入您的特别需求...",
    characters_remaining: "剩余字符",
    share_url: "分享链接",
    addtocart_success: "操作成功！物品已加入购物车",
    invalidproduct_error: "抱歉！无效产品组合 ",
  },

  cartlist: {
    title: "您的订单详情",
    subtitle: "您的物品",
    orderhandl: "订单处理方式",
    deliverylocation: "送货地点",
    pickuplocation: "自取地点",
    deliverydate: "送货日期",
    pickupdate: "取货日期",
    deliverytime: "送货时间",
    pickuptime: "取货时间",
    cartclear: "清空购物车",
    subtotalLbl: "小计",
    deliveryLbl: "送货",
    additional_deliveryLbl: "添加送货",
    promo_code: "优惠码",
    gstLbl: "政府税",
    totalLbl: "总额",
    checkout: "结账",
  },

  checkout: {
    title: "结账",
    subtitle: "查看您的订单详情和选择付款方式",
    subtitle_addon: "您要添加这些项目吗？",
    locationtime: "您的地点和时间",
    pickup_location_detile: "自我收集详细信息",
    pickup_type_header: "你想如何拥有它",
    pickup_type_one: "在这里",
    pickup_type_two: "去",
    deliverylocation: "送货地点",
    address_placeholder: "举例。地址规则要求",
    postal_code: "邮编",
    unit_no1: "第一单元",
    unit_no2: "第二单元",
    change_delivery_address: "更改送货地址",
    billing_address_lbl: "账单地址",
    same_as_address: "同送货地址",
    pickuplocation: "取货地点",
    change_pickup_location: "更改取货地点",
    order_date_time: "订单日期和时间",
    special_instruction_lbl: "特别指示",
    order_details: "您的订单详情",
    order_items: "您的物品",
    clear_cart: "清空购物车",
    redeem_title: "使用兑换",
    redeem_subtitle: "从这里兑换您的积分",
    redeem_chk_title: "兑换积分",
    redeem_chk_btn: "查询我的积分",
    reward_pointlink_text1: "您有",
    reward_pointlink_text2: "积分",
    reward_pointlink_text3: "可使用",
    reward_placeholder1: "您可以兑换 ",
    reward_placeholder2: " 积分",
    rewardpromo_apply: "现在申请",
    rewardpromo_remove: "清除",
    reward_point_success: "成功获得奖励积分",
    reward_point_error: "抱歉！无法获得奖励积分",
    promo_title: "折扣码",
    promo_subtitle: "在这里申请您的折扣码",
    promo_pointlink_text2: "促销活动",
    promo_lable: "在这里添加您的折扣码",
    promo_code_success: "成功获得促销活动",
    promo_code_error: "抱歉！无法获得折扣码",
    order_reward_ern1: "您会获得",
    order_reward_ern2: "奖励",
    order_reward_ern3: "此订单的积分",
    payment_method_title: "选择您的付款方式",
    payment_method_cash: "货到付款",
    payment_method_promotion: "促销活动",
    place_order: "下订单",
    online_pay_title: "提示",
    online_pay_subtitle: "很好！非常好！",
    online_pay_action: "进行付款",
    processing_popup_title: "我们在处理您的订单",
    processing_text: "处理中",
    processing_card_info: "正在验证卡片信息",
    processing_order_placing: "现在确定您的订单",
    processing_order_completing: "完成您的网上支付",
    order_outletdata_error: "抱歉！ 您的订单网点信息为空",
    order_zonedata_error: "抱歉！ 您的订单区信息为空",
    cart_empty_error: "抱歉！您的购物车为空的",
    mobileno_empty_error: "请更新您的手机号码",
    order_datetime_error: "请选择您的订单日期和时间",
    order_billingaddres_error: "请输入您的默认账单地址",
    order_unitno_error: "请输入单元号码",
    order_captureAmt_error: "您的订单失败",
    order_paymentFail_error1: "错误码：1001 哎呀 ！数据错误！请再次尝试",
    order_paymentFail_error2: "错误码：1002 哎呀 ！无法连接服务器！请再次尝试",
    order_paymentFail_error3: "错误码：1003 ！哎呀数据错误！请再次尝试  ",
    order_paymentFail_error4: "错误码：1004 哎呀 无法处理您的订单！请再次尝试",
  },

  rewardotppopup: {
    title: "输入您的一次性密码",
    otpresend_link1: "没有收到？",
    otpresend_link2: "重新发送一次性密码",
    otpresend_link3: "重复",
    submit_btn: "继续",
    otp_send_success: "请检查一次性密码已发送至登记手机号码",
    otp_send_error: "抱歉！请稍等片刻再试！",
    otp_verified_success: "一次性密码验证成功",
    otp_verified_error: "抱歉！您输入的一次性密码无效",
  },

  thankyoupage: {
    title: "谢谢",
    subtitle: "您的订单已处理成功",
    order_no: "订单号码",
    order_placed: "下单地点",
    order_payby: "付款方式",
    chkorder: "检查下单状态",
    order_invalid: "无效订单详情",
  },

  myaccount: {
    title: "我的账号",
    subtitle: "允许查看我的近期的活动日志、检查更新我的积分和账号信息",
    account_link: "账号详情",
    order_link: "订单",
    reward_link: "奖励",
    promotion_link: "促销活动",
    account_plchrd: "我的账号",
    order_plchrd: "我的订单",
    reward_plchrd: "我的奖励",
    promotion_plchrd: "我的折扣",
  },

  myprofile: {
    title: "账号信息",
    subtitle: "允许查看我的近期的活动日志、检查更新我的积分和账号信息",
    joinedon: "加入日期",
    earned_points: "获得积分总数",
    points_expiring: " 积分将在30天后失效",
    change_password: "更换密码",
    logout_lnk: "注销",
    hello: "你好",
    account_info: "您的账号信息",
    firstname: "姓名",
    lastname: "姓",
    nickname: "名字",
    mobile: "手机号码",
    email: "电子邮件地址",
    birthday: "生日",
    gender_lbl1: "男士",
    gender_lbl2: "女士",
    gender_lbl3: "不具体",
    address: "地址",
    postal_code: "邮编",
    address_line1: "地址列1",
    unit_num1: "单元号码1",
    unit_num2: "单元号码2",
    update_btn: "更新",
    other_address: "其他地址",
  },

  changepasswdpopup: {
    title: "更换密码",
    current_pwd: "当前密码（旧密码）",
    new_pwd: "新密码",
    reenter_pwd: "重新输入新密码",
    submit_btn: "提交",
    success_msg: "成功更换密码！",
  },

  billingaddrpopup: {
    title: "账单地址",
    postal_code: "邮编",
    postal_code_error: "这是无效的邮编",
    address_ln: "地址栏",
    unit_no1: "单元号码1",
    unit_no2: "单元号码2",
    submit_btn: "提交",
    success_msg: "谢谢！您的账单地址添加成功",
    deleted_msg: "地址成功删除",
  },

  myorders: {
    delivery_takeaway: "Delivery / Self Collection",
    current_orders: "最近的订单",
    past_orders: "往期订单",
    loat_more: "下载更多",
    order_no: "订单号码",
    view_recipt: "查看收据",
    print_invoice: "打印发货单",
    no_records: "无记录查询",
    delivery_frm: "出货",
    delivery_to: "收货",
    email_receipt: "电子邮件收据",
    order_items: "订单项目",
    delivery_charges: "物流费用",
  },

  myrewards: {
    title: "您目前的奖励积分",
    points: "积分",
    rewards_earn: "获得奖励",
    rewards_redeem: "兑换奖励",
    time: "时间",
    discount: "折扣",
  },

  mypromo: {
    title: "兑换您的",
    promotions: "促销活动",
    placeholder: "从这里加入您的折扣码",
    apply_btn: "申请",
    promotions_used: "使用的优惠促销",
    promotion_name: "促销名称",
    valid_till: "有效期至",
    view_now: "查看",
    redeem: "兑换",
    empty_promo: "请输入您的促销码",
    cart_valid: "购物车数量不足以申请参加促销活动",
    promo_code_success: "加入促销活动成功",
    promo_code_error: "抱歉！申请折扣码失败",
  },

  footer: {
    copyright: "版权所有 " + yearSp + " 马基山. 保留所有权利。设计由扬科软",
  },

  common: {
    countrylable: "新加坡",
    /*countrylable:'印度尼西亚',*/
    orderhandledtext: "我们的工作人员将带您进入。",
    minutestext: "一会儿",
    alertsuccesstitle: "成功",
    alerterrortitle: "错误",
    alertbutton: "好的",
    account_activation_success: "您的账号已成功激活，请登陆查看。",
    account_activation_error: "您的激活链接已过期，请联络您的客服。",
    addtocart_success: "不错的选择！您的货物已加入购物车",
    addtocart_error: "抱歉！物品加入购物车失败",
    addons_header: "附加",
  },
};
