/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { stripslashes } from "../Helpers/SettingHelper";
import { cateringId, productNoImg } from "../Helpers/Config";
import cookie from "react-cookies";
import { GET_CATEGORY_LIST } from "../../actions";
var Parser = require("html-react-parser");

class Maincategory extends Component {
  constructor(props) {
    super(props);
    this.state = { categorylist: [], imagepath: [], loading: true };
  }
  componentDidMount() {
    this.props.getCategoryList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categorylist.length > 0) {
      if (this.state.categorylist !== nextProps.categorylist) {
        if (Object.keys(nextProps.categorylist).length > 0) {
          if (nextProps.categorylist[0].status === "ok") {
            this.setState(
              {
                categorylist: nextProps.categorylist[0].result_set,
                imagepath: nextProps.categorylist[0].common,
                loading: false,
              },
              function () {
                console.log(this.state.categorylist, "this.state.categorylist");
                if (this.state.categorylist.length === 1) {
                  if (this.state.categorylist[0].subcategory.length > 0) {
                    this.props.history.push(
                      "/catering/" +
                        this.state.categorylist[0].pro_cate_slug +
                        "/" +
                        this.state.categorylist[0].subcategory[0]
                          .pro_subcate_slug
                    );
                  }
                }
              }
            );
          }
        }
      }
    }
  }
  gotoCatering(link, event) {
    event.preventDefault();
    if (
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
    ) {
      if (cookie.load("defaultAvilablityId") !== cateringId) {
        cookie.save("defaultAvilablityId", cateringId, { path: "/" });
        this.props.PropsData.history.push(link);
      } else {
        this.props.PropsData.history.push(link);
      }
    } else {
    }
  }

  renderCategory() {
    let cat,
      firstcategory = "";

    if (this.state.categorylist !== "") {
      if (this.state.categorylist.length > 0) {
        cat = this.state.categorylist.map((item, index) => {
          if (item.subcategory.length > 0) {
            return (
              <li key={index}>
                <Link
                  to={
                    "/catering/" +
                    item.pro_cate_slug +
                    "/" +
                    item.subcategory[0].pro_subcate_slug
                  }
                >
                  <div className="catering-packages_item">
                    <div className="catering-packages_item_image">
                      {item.pro_cate_image !== "" &&
                      item.pro_cate_image !== null ? (
                        <img
                          src={
                            this.state.imagepath.cat_image_source +
                            "/" +
                            item.pro_cate_image
                          }
                        />
                      ) : (
                        <img src={productNoImg} />
                      )}
                    </div>
                    <div className="catering-packages_item_name">
                      <h5>{stripslashes(item.pro_cate_name)}</h5>
                    </div>
                  </div>
                </Link>
              </li>
            );
          }
        });
      }
    }
    return cat;
  }

  render() {
    return (
      <>
        <Header />
        <section className="catering-category-banner">
          <div className="catering-banner-inner">
            <h3 className="catering-service">Sushi Catering Service</h3>
            <span className="catering-service-sub">For Parties & Events</span>
            <Link to={"/catering/packages-list"}>
              <button className="catering-explore-btn">
                Explore Our Packages
              </button>
            </Link>
          </div>
        </section>
        <div className="catering-container">
          <h1 className="caterer-for-you">The sushi caterer for you.</h1>
          <p className="catering-desc">
            From bentos to premium bowls, we have the perfect menu options for
            your parties and events.
          </p>
          <div className="catering-link-wrapper">
            <Link to={"/catering/packages-list"}>
              <a className="catering-package-list-link">
                Yes, I would like to order
              </a>
            </Link>
          </div>

          <h2 className="catering-package-header">Catering Packages</h2>

          <section className="catering-packages_wrapper">
            <div className="container">
              {!this.state.loading && (
                <div className="catering-packages_list">
                  <ul>{this.renderCategory()}</ul>
                </div>
              )}
            </div>
          </section>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    categorylist: state.categorylist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Maincategory);
