/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import axios from "axios";
import DocumentMeta from "react-document-meta";
import {
  appId,
  apiUrlV2,
  languageCd,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
} from "../Helpers/Config";
const lang = require("../Helpers/Language/lang" + "-" + languageCd);
import { stripslashes, showLoader } from "../Helpers/SettingHelper";
import { setMinutes, setHours, getDay, format } from "date-fns";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import StaticBanner from "./HomeSubCompo/StaticBanner";
import { GET_STATIC_BLOCK } from "../../actions";

import orderNowBgImg from "../../common/images/order-now-bg.png";
import orderNowHvrImg from "../../common/images/order-now-hover.png";
import deliveryImg from "../../common/images/delivery.svg";
import takeawayImg from "../../common/images/takeaway.png";
/*import takeawayImg from "../../common/images/takeaway.svg";*/
import cateringImg from "../../common/images/catering.svg";
import reservationImg from "../../common/images/reservation.svg";
import servingImg from "../../common/images/serving-passionately.jpg";
import makanIconImg from "../../common/images/muthu-makan-logo.png";
import block1Img from "../../common/images/block-1.jpg";
import block2Img from "../../common/images/block-2.jpg";
import block3Img from "../../common/images/block-3.jpg";

var Parser = require("html-react-parser");

const meta = {
  title: lang.metadata.home_title,
  description: lang.metadata.home_desc,
  meta: {
    name: {
      keywords: lang.metadata.home_name_key,
    },
  },
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      orderTypeBlks: "",
    };
    cookie.remove("popuptriggerFrom", { path: "/" });
    this.props.getStaticBlock();
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var orderTypBlk = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          /*if(languageCd === 'en' && data.staticblocks_slug === 'order-type-block') {*/
          if (data.staticblocks_slug === "order-type-block") {
            orderTypBlk = data.staticblocks_description;
            return "";
          }
        });
      }
      orderTypBlk =
        orderTypBlk !== "" && orderTypBlk !== null
          ? Parser(orderTypBlk)
          : orderTypBlk;
      this.setState({
        staticblacks: PropsData.staticblack,
        orderTypeBlks: orderTypBlk,
      });
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };

  chooseAvailability(availability) {
    /*if(availability === deliveryId){
		  return false;
		}*/
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
      /*return false;*/
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/catering");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /* popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {
    var qrcodeScannerAction =
      cookie.load("qrcodeScannerAction") != "" &&
      cookie.load("qrcodeScannerAction") != undefined
        ? cookie.load("qrcodeScannerAction")
        : localStorage.getItem("qrcodeScannerAction");
    var tempQrcodeOutlet =
      cookie.load("qrcodeOutlet") != "" &&
      cookie.load("qrcodeOutlet") != undefined
        ? cookie.load("qrcodeOutlet")
        : localStorage.getItem("qrcodeOutlet");

    if (
      qrcodeScannerAction === "yes" &&
      tempQrcodeOutlet != "" &&
      tempQrcodeOutlet != undefined &&
      tempQrcodeOutlet != null
    ) {
      var qrcodeOutlet = tempQrcodeOutlet;
      localStorage.removeItem("qrcodeScannerAction");
      cookie.remove("qrcodeScannerAction", { path: "/" });

      var availability = pickupId;
      var defaultAvilTy = cookie.load("defaultAvilablityId");

      if (defaultAvilTy !== availability) {
        var cartTotalItems = cookie.load("cartTotalItems");
        cartTotalItems =
          cartTotalItems != "" && cartTotalItems != undefined
            ? parseInt(cartTotalItems)
            : 0;
        if (defaultAvilTy === cateringId && cartTotalItems > 0) {
          this.setState({ nextavail: availability });
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        } else if (
          cookie.load("orderOutletId") != "" &&
          cookie.load("orderOutletId") != undefined
        ) {
          this.setState({ nextavail: availability });
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      }

      var loaddataArr = localStorage.getItem("qrcodeOutletData");

      if (
        loaddataArr != "" &&
        loaddataArr != undefined &&
        loaddataArr != null
      ) {
        showLoader("home-main-div", "class");
        var loaddataArrNew = JSON.parse(loaddataArr);
        var loaddata = loaddataArrNew[0];
        console.log("loaddataArr", loaddataArrNew);
        console.log("loaddata", loaddata);

        var orderHandled =
          stripslashes(loaddata.outlet_name) +
          " " +
          loaddata.outlet_address_line1 +
          " " +
          loaddata.outlet_address_line2 +
          ", " +
          lang.common.countrylable +
          " " +
          loaddata.outlet_postal_code;

        var orderDateTime = new Date();
        var OrderHours = orderDateTime.getHours();
        var OrderMunts = orderDateTime.getMinutes();
        var OrderSecnd = orderDateTime.getSeconds();

        var deliveryDate = format(orderDateTime, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);

        var pickupHavingHere = loaddata.outlet_pickup_having_here;
        var pickupToGo = loaddata.outlet_pickup_to_go;

        /*var hr_this = this;
				setTimeout(function(){
					hr_this.props.history.push('/products');
				},500);*/

        setTimeout(function () {
          cookie.save("orderDateTime", orderDateTime, { path: "/" });
          cookie.save("deliveryDate", deliveryDate, { path: "/" });
          cookie.save("deliveryTime", deliveryTime, { path: "/" });

          cookie.save("orderOutletId", loaddata.outlet_id, { path: "/" });
          cookie.save("orderOutletName", stripslashes(loaddata.outlet_name), {
            path: "/",
          });
          cookie.save("orderPostalCode", loaddata.outlet_postal_code, {
            path: "/",
          });
          cookie.save("orderTAT", loaddata.outlet_pickup_tat, { path: "/" });
          cookie.save("orderHandled", orderHandled, { path: "/" });
          cookie.save("defaultAvilablityId", pickupId, { path: "/" });
          cookie.save("orderHandledByText", orderHandled, { path: "/" });
          cookie.save("pickupHavingHere", pickupHavingHere, { path: "/" });
          cookie.save("pickupToGo", pickupToGo, { path: "/" });

          cookie.save("outletchosen", pickupId, { path: "/" });

          window.location.replace("/products");
        }, 600);
      }
    }

    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("cateringPay") === "initial") {
      cookie.save("cateringPay", "start", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    setTimeout(function () {
      if ($(".address-list-main").length > 0) {
        $(".address-list-main").mCustomScrollbar();
        $(".address-list-error").mCustomScrollbar();
      }
    }, 1000);
  }

  checkActiveDiv(avlType) {
    var clsTxt = "home-ordernow-single ";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } else if (
      availability == avlType &&
      (avlType === cateringId || avlType === reservationId)
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  render() {
    return (
      <div className={"selected-lang-" + languageCd}>
        <DocumentMeta {...meta} />
        <div className="common-top-div home-main-div">
          {/* Header section */}
          <Header
            homePageState={this.state}
            cartTriggerFlg={this.state.cartTriggerFlg}
            sateValChange={this.sateValChange}
          />

          {/* Home banner section */}
          <HomeBanner />

          {/* Static Banner section */}
          <div className="static-banner-section">
            <div className="static-banner-left">
              <StaticBanner
                bannerFlag="left"
                staticBlk={this.state.staticblacks}
              />
            </div>
            <div className="static-banner-left">
              <StaticBanner
                bannerFlag="right"
                staticBlk={this.state.staticblacks}
              />
            </div>
          </div>

          {/* block three section */}
          <section className="block-three-section">
            {this.state.orderTypeBlks}
            {/*<div className="container">
							<div className="bts-inner">
								<ul>
									<li>
										<div className="bts-img">
											<a href="javascript:;"><img src={block1Img} /></a>
										</div>
										<div className="bts-info">
											<h3>Story</h3>
											<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy 
text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            <a href="javascript:;" className="button">Read More</a>
										</div>
									</li>
                <li>
										<div className="bts-img">
											<a href="javascript:;"><img src={block2Img} /></a>
										</div>
										<div className="bts-info">
											<h3>Delivery</h3>
											<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy 
text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            <a href="javascript:;" className="button">Order Now</a>
										</div>
									</li>
                <li>
										<div className="bts-img">
											<a href="javascript:;"><img src={block3Img} /></a>
										</div>
										<div className="bts-info">
											<h3>Order, Pay, Collect</h3>
											<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy 
text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            <a href="javascript:;" className="button">Order Now</a>
										</div>
									</li>
								</ul>
							</div>
	  </div>*/}
          </section>
        </div>
        {/* container home-main-div -- end */}

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
