/* eslint-disable */
import React, { Component } from "react";
/*import Axios from 'axios';*/
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import { cateringId, reservationId, languageCd } from "../Helpers/Config";
import { showLoader, hideLoader } from "../Helpers/SettingHelper";
import { GET_MENU_NAVIGATION } from "../../actions";
const isEqual = require("react-fast-compare");

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedSlugType: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
    };

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === cateringId) {
      this.props.history.push("/catering");
      return;
    }

    if (avilablityIdTxt === reservationId) {
      this.props.history.push("/reservation");
      return;
    }

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return;
    }

    this.props.getMenuNavigationList();
  }

  componentWillReceiveProps(nextProps) {
    /*if(this.state.selectedCategoryName!==nextProps.selectedCatry){
			this.setState({selectedCategoryName: nextProps.selectedCatry});
		}*/

    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let selectedSlugType = nextProps.selectedSlugType;
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
    }

    if (slugValue !== "") {
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {
      showLoader("productlist-main-div", "class");

      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        selectedCategoryName: categoryNameTxt,
      });
    }
  }

  componentDidMount() {
    /*SCROLL FIXED PRODUCT PAGE*/
    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  render() {
    return (
      <div className={"selected-lang-" + languageCd}>
        <div className="container common-top-div productpage-main-div">
          {/* Header start */}
          <Header
            cartTriggerFlg={this.state.cartTriggerFlg}
            sateValChange={this.sateValChange}
            showCatryName={this.state.selectedCategoryName}
          />
          {/* Header End */}

          <MenuNavigation {...this.props} productState={this.state} />

          <ProductList
            {...this.props}
            productState={this.state}
            sateValChange={this.sateValChange}
          />
        </div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        selectedCatry = tempArr[0].result_set[0].category_name;
        menu_slug = tempArr[0].result_set[0].pro_cate_slug;
        menu_type =
          tempArr[0].result_set[0].menu_type == "main"
            ? "category"
            : "subcategory";
      }
    }
  }

  return {
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
