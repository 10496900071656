var todayTimeSp = new Date();
var yearSp = todayTimeSp.getFullYear();

module.exports = {
  metadata: {
    home_title: "Welcome to Maki-San",
    home_desc: "Welcome to Maki-San",
    home_name_key: "Welcome to Maki-San",
  },

  /* Home page navigation items */
  navigation: {
    login: "Login",
    signup: "Signup",
    register: "Register",
    myaccount: "My Account",
    logout: "Logout",
    changepassword: "Change Password",
    searchboxlbl: "Search...",
    searchnoresult: "No Product found",
    orderNow: "Order Now",
  },

  /* Face book  */
  fb: {
    fbURL: "",
    fbImage: "",
    fbTitle: "",
    fbDesc: "",
  },

  /* Twitter  */
  tw: {
    twURL: "",
    twTitile: "",
    twvia: "",
  },

  /* google Plus data   */
  gp: {
    gpURL: "",
  },

  countrypopup: {
    selected: "Singapore",
    /*selected: 'Indonesia',*/
    title: "Let Us Know Your Location",
    countrylable1: "Singapore",
    countrylable2: "Indonesia",
  },

  availability: {
    delivery: "Delivery",
    takeaway: "Self Collection",
    catering: "Catering",
    reservation: "Reservation",
  },

  availabilitypopup: {
    title: "Order Now",
    subtitle: "Select your order type",
    delivery: "Delivery",
    takeaway: "Self Collection",
  },

  deliverypopup: {
    title: "Let us know",
    subtitle: "Your Delivery Location",
    postalcodelbl: "Enter your postal code",
    newaddresslbl: "Enter New Address",
    postalcodeerror: "Please enter valid postal code.",
    backbtn: "Go Back",
    continuebtn: "Continue",
  },

  deliveryerrorpopup: {
    title: "Sorry",
    subtitle: "We cant find your postal code",
    postalcodelbl: "Enter your postal code",
    postalcodeerror: "Please enter valid postal code.",
    addresserror_lbl1: "We can`t Deliver At the Moment!",
    addresserror_lbl2: "Please come back again.",
    change_address: "change address",
    change_outlet: "change outlet",
    continuebtn: "Continue",
  },

  deliverysuccesspopup: {
    title: "Awesome",
    subtitle: "We can Deliver !",
    deliveryaddresslbl: "Your Delivery Address",
    pickupaddresslbl: "Your Pickup location Is",
    deliverydateselectlbl: "Select Your Delivery Date & Time",
    pickupdateselectlbl: "Select Your Pickup Date & Time",
    continuebtn: "Continue",
  },

  takeawaypopup: {
    title: "Please Select",
    subtitle: "Your Self Collection Outlet",
    delvry_subtitle: "Your Delivery Outlet",
    searchoutletlbl: "Search Outlet",
    searchoutleterror: "Please choose one outlet.",
    searchoutleterror_map: "Please search and choose one outlet.",
    innerlbl: "Nearby Outlets",
    nooutletfnd: "No Outlet found",
    continuebtn: "Continue",
  },

  warningpopup: {
    title: "Warning",
    subtitle: "By switching you are about to clear your cart.",
    subtitle_lang:
      "All existing changes including items in cart will be cleared.",
    description: "Do you wish to proceed ?",
    nobtn: "No",
    yesbtn: "Yes",
  },

  loginpopup: {
    title: "Have an account ?",
    subtitle: "Log in with your details to manage.",
    fbnametext: "Login with Facebook",
    guestloginbtn: "No I Want To Checkout As Guest",
    separator: "Or",
    innerlbl: "Login with",
    emaillabel: "Email Address",
    passwordlabel: "Password",
    isemptyerror: "This field is required.",
    validemail: "This is not a valid email.",
    createaccountlink: "Create an account",
    forgotpwdlink: "Forgot Password",
    submitbtn: "Submit",
    successmsg: "Logged in Successfully!",
    errormsg: "Invalid Login Credentials",
  },

  signuppopup: {
    title: "New to Makisan ?",
    subtitle: "Create your Makisan account.",
    fbnametext: "Login with Facebook",
    backbtn: "BACK",
    separator: "Or",
    innerlbl: "Create account with",
    infolabel: "Your Information",
    firstnamelabel: "First Name",
    lastnamelabel: "Last Name",
    mobilenamelabel: "Mobile Number",
    loginlabel: "Your Login",
    emaillabel: "Email Address",
    passwordlabel: "Password",
    confirmpwdlabel: "Confirm Password",
    isemptyerror: "This field is required.",
    validemail: "This is not a valid email.",
    alreadymail: "Email already exists.",
    validemobile: "please enter valid Mobile Number.",
    alreadymobile: "Mobile number already exists.",
    validepassword: "Password must be at least 6 characters.",
    valideconfirmpwd: "Passwords do not match.",
    submitbtn: "Submit",
    successmsg: "Mail has been sent.",
  },

  guestpopup: {
    title: "New to Makisan ?",
    subtitle: "Create your Makisan account.",
    backbtn: "BACK",
    innerlbl: "Guest Checkout Details",
    mobilelabel: "Phone number",
    namelabel: "Name",
    emaillabel: "Email",
    isemptyerror: "This field is required.",
    validemail: "This is not a valid email.",
    validemobile: "please enter valid Mobile Number.",
    submitbtn: "Submit",
  },

  forgotpasswordpopup: {
    title: "Forgot your password ?",
    subtitle: "You can reset your password here.",
    backbtn: "Back to login",
    emaillabel: "Email Address",
    isemptyerror: "This field is required.",
    validemail: "This is not a valid email.",
    submitbtn: "Get Reset Link",
  },

  resetpasswordpopup: {
    title: "Reset Password",
    newpasswordlabel: "New Password",
    confirmpasswordlabel: "Confirm Password",
    newpassworderror: "New password is required.",
    confirmpasserror: "Confirm password is required.",
    submitbtn: "Submit",
  },

  homebannerinfo: {
    title: "The Original D.I.Y. Sushi and Salad Brand",
    subtitle: "Now in Singapore and Indonesia! More Countries Very Soon!",
    orderNow: "Order Now",
  },

  product: {
    ordernowbtn: "Order Now",
    soldoutbtn: "Sold Out",
    addtocartbtn: "Add to Cart",
    donebtn: "Done",
    specialremark_placehldr:
      "You can enter your special remark in the section...",
    characters_remaining: "Characters remaining",
    share_url: "Share URL",
    addtocart_success: "Successfully! Item added to your cart.",
    invalidproduct_error: "Sorry!. Invalid product combination.",
  },

  cartlist: {
    title: "YOUR ORDER DETAILS",
    subtitle: "Your Items",
    orderhandl: "Order Handled By",
    deliverylocation: "Delivery Location",
    pickuplocation: "Pickup Location",
    deliverydate: "Delivery Date",
    pickupdate: "Pickup Date",
    deliverytime: "Delivery Time",
    pickuptime: "Pickup Time",
    cartclear: "CLEAR CART",
    subtotalLbl: "SUBTOTAL",
    deliveryLbl: "Delivery",
    additional_deliveryLbl: "Additional Delivery",
    promo_code: "Promo Code",
    gstLbl: "GST",
    totalLbl: "Total",
    checkout: "Checkout",
  },

  checkout: {
    title: "Checkout",
    subtitle: "Check your order details and select your payment options",
    subtitle_addon: "Would you like to add these items ?",
    locationtime: "Your Location & Time",
    pickup_location_detile: "Self Collection Details",
    pickup_type_header: "How would you like to have it",
    pickup_type_one: "HAVING HERE",
    pickup_type_two: "TO GO",
    deliverylocation: "Delivery Location",
    address_placeholder: "Block 37, Jalan Rumah Tinggi",
    postal_code: "Postal Code",
    unit_no1: "Unit No 01",
    unit_no2: "Unit No 02",
    change_delivery_address: "Change Delivery Address",
    billing_address_lbl: "Billing Address",
    same_as_address: "Same As Delivery Address",
    pickuplocation: "Pickup Location",
    change_pickup_location: "Change Pickup Location",
    order_date_time: "Order Date & Time",
    special_instruction_lbl: "Special Instruction",
    order_details: "Your Order Details",
    order_items: "Your Items",
    clear_cart: "CLEAR CART",
    redeem_title: "Get Redeem",
    redeem_subtitle: "Redeem your points here",
    redeem_chk_title: "Redeem Points",
    redeem_chk_btn: "Check My Points",
    reward_pointlink_text1: "You have",
    reward_pointlink_text2: "points",
    reward_pointlink_text3: "available",
    reward_placeholder1: "You Can Redeem ",
    reward_placeholder2: " Points",
    rewardpromo_apply: "Apply Now",
    rewardpromo_remove: "Remove",
    reward_point_success: "Rewards Points applied successfully",
    reward_point_error: "Sorry! Did not applied Rewards Points",
    promo_title: "Promo Code",
    promo_subtitle: "Apply your promo code here",
    promo_pointlink_text2: "Promotions",
    promo_lable: "Add Your Promo Code Here",
    promo_code_success: "Promotion applied successfully",
    promo_code_error: "Sorry! Did not applied promo code",
    order_reward_ern1: "You will get",
    order_reward_ern2: "reward",
    order_reward_ern3: "points with this order",
    payment_method_title: "Select Your Payment Method",
    payment_method_cash: "Cash On Delivery",
    payment_method_promotion: "Promotion",
    place_order: "Place Order",
    online_pay_title: "Alert!",
    online_pay_subtitle: "Awesome ! You are all set",
    online_pay_action: "Proceed To Payment",
    processing_popup_title: "We Are Processing Your Order",
    processing_text: "Processing",
    processing_card_info: "Validating card information.",
    processing_order_placing: "Placing your order now.",
    processing_order_completing: "Completing your online payment.",
    order_outletdata_error: "Sorry!. Your order outlet detail is empty.",
    order_zonedata_error: "Sorry!. Your order zone detail is empty.",
    cart_empty_error: "Sorry!. Your cart is empty.",
    mobileno_empty_error: "Please update your mobile no.",
    order_datetime_error: "Please select order date and time.",
    order_billingaddres_error: "Please enter default billing address.",
    order_unitno_error: "Please enter unit No.",
    order_captureAmt_error: "Your order was not successful.",
    order_paymentFail_error1:
      "Error code: 1001 Oops! Something went wrong! Please try again.",
    order_paymentFail_error2:
      "Error code: 1002 Oops! Unable to connect to server. Please try again.",
    order_paymentFail_error3:
      "Error code:1003 Oops! Something went wrong! Please try again.",
    order_paymentFail_error4:
      "Error code: 1004 Oops! Unable to processing your order. Please try again.",
  },

  rewardotppopup: {
    title: "Enter Your OTP",
    otpresend_link1: "Didnt receive?",
    otpresend_link2: "Resend OTP",
    otpresend_link3: "Again",
    submit_btn: "Continue",
    otp_send_success: "Verification OTP was send your register mobile number.",
    otp_send_error: "Sorry! try again after some time",
    otp_verified_success: "OTP was verified successfully.",
    otp_verified_error: "Sorry! your entered invalid OTP. Please try again.",
  },

  thankyoupage: {
    title: "Thank You",
    subtitle: "Your order has been placed successfully",
    order_no: "Order No",
    order_placed: "Order placed at",
    order_payby: "Pay by",
    chkorder: "Check Order Status",
    order_invalid: "Invalid order detail.",
  },

  myaccount: {
    title: "My Account",
    subtitle:
      "My Account Dashboard allows you to view your recent activities, check your reward points and update account information.",
    account_link: "Account Details",
    order_link: "Orders",
    reward_link: "Rewards",
    promotion_link: "Promotions",
    account_plchrd: "My Account",
    order_plchrd: "My Orders",
    reward_plchrd: "My Rewards",
    promotion_plchrd: "My Promotions",
  },

  myprofile: {
    title: "Account Information",
    subtitle:
      "My Account Dashboard allows you to view your recent activities, check your reward points and update account information.",
    joinedon: "Date Joined",
    earned_points: "Total Points Earned",
    points_expiring: " Points Expiring In 30 Days",
    change_password: "Change Password",
    logout_lnk: "Logout",
    hello: "Hello",
    account_info: "Your Account Information",
    firstname: "Name",
    lastname: "Last Name",
    nickname: "PreferredName",
    mobile: "Mobile Number",
    email: "Email Address",
    birthday: "Birthday",
    gender_lbl1: "Male",
    gender_lbl2: "Female",
    gender_lbl3: "Unspecified",
    address: "Address",
    postal_code: "Postal Code",
    address_line1: "Address Line 1",
    unit_num1: "Unit Number 1",
    unit_num2: "Unit Number 2",
    update_btn: "Update",
    other_address: "Other Address",
  },

  changepasswdpopup: {
    title: "Change Password",
    current_pwd: "Current Password",
    new_pwd: "New Password",
    reenter_pwd: "Re-enter New Password",
    submit_btn: "Submit",
    success_msg: "Password changed successfully!",
  },

  billingaddrpopup: {
    title: "Billing Address",
    postal_code: "Postal Code",
    postal_code_error: "This is not a valid postal code.",
    address_ln: "Address line",
    unit_no1: "Unit Number 1",
    unit_no2: "Unit Number 2",
    submit_btn: "Submit",
    success_msg: "Nice! Your billing address added successfully.",
    deleted_msg: "Address deleted successfully!",
  },

  myorders: {
    delivery_takeaway: "Delivery / Self Collection",
    past_orders: "Past Orders",
    current_orders: "Current Orders",
    loat_more: "Load More",
    order_no: "ORDER NO",
    view_recipt: "View Receipt",
    print_invoice: "Print Invoice",
    no_records: "No records found",
    delivery_frm: "Delivery From",
    delivery_to: "Deliver TO",
    email_receipt: "Email Receipt",
    order_items: "Order Items",
    delivery_charges: "Delivery Charges",
  },

  myrewards: {
    title: "Your Current Reward Points",
    points: "Points",
    rewards_earn: "REWARDS EARNED",
    rewards_redeem: "REWARDS REDEEMED",
    time: "time",
    discount: "Discount",
  },

  mypromo: {
    title: "Redeem your",
    promotions: "Promotions",
    placeholder: "Add Your Promo Code Here",
    apply_btn: "APPLY",
    promotions_used: "Promotions Used",
    promotion_name: "Promotion Name",
    valid_till: "Valid till",
    view_now: "View Now",
    redeem: "Redeem",
    empty_promo: "Please enter your Promo Code.",
    cart_valid: "Cart Quantity is not enough to apply promotion.",
    promo_code_success: "Promotion applied successfully",
    promo_code_error: "Sorry! Did not applied promo code",
  },

  footer: {
    copyright:
      "Copyright " +
      yearSp +
      " Maki San. All rights reserved. Design By Jankosoft",
  },

  common: {
    countrylable: "Singapore",
    /*countrylable:'Indonesia',*/
    orderhandledtext: "Crew will be seeing you in",
    minutestext: "Minutes",
    alertsuccesstitle: "Success",
    alerterrortitle: "Error",
    alertbutton: "OK",
    account_activation_success:
      "Your account has been successfully activated. Please login to continue.",
    account_activation_error:
      "Your activation link has been expired. Please contact your admin.",
    addtocart_success: "Great choice! Item added to your cart.",
    addtocart_error: "Sorry! Products can`t add your cart.",
    addons_header: "Add-ons",
  },
};
