/* eslint-disable */
import React, { Component } from "react"
import { Link } from "react-router-dom"
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
import { stripslashes } from "../Helpers/SettingHelper"
import { appId, apiUrlCtrng, cateringId, productNoImg } from "../Helpers/Config"
import cookie from "react-cookies"
import axios from "axios"
import { connect } from "react-redux"
import { GET_CATEGORY_LIST } from "../../actions"
var Parser = require("html-react-parser")

class PackageList extends Component {
  constructor(props) {
    super(props)

    var cateSlug,
      subcateSlug = ""
    if (this.props.match.params !== "" && typeof this.props.match.params !== undefined && typeof this.props.match.params !== "undefined") {
      cateSlug = this.props.match.params.catSlug !== "" && typeof this.props.match.params.catSlug !== undefined && typeof this.props.match.params.catSlug !== "undefined" ? this.props.match.params.catSlug : ""
      subcateSlug = this.props.match.params.subCatSlug !== "" && typeof this.props.match.params.subCatSlug !== undefined && typeof this.props.match.params.subCatSlug !== "undefined" ? this.props.match.params.subCatSlug : ""
    }

    this.state = {
      cateSlug: cateSlug,
      subcateSlug: subcateSlug,
      isListing: cateSlug === "packages-list",
      displaypackage: [],
    }
  }
  componentDidMount() {
    if (!this.state.isListing) {
      this.loadProductList()
    } else if (this.props.getCategoryList) {
      this.props.getCategoryList()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categorylist.length > 0) {
      if (this.state.categorylist !== nextProps.categorylist) {
        if (Object.keys(nextProps.categorylist).length > 0) {
          if (nextProps.categorylist[0].status === "ok") {
            this.displayPackage(nextProps.categorylist[0].result_set, nextProps.categorylist[0].common, true)
          }
        }
      }
    }
  }

  loadProductList() {
    var params = "app_id=" + appId + "&subcategory_slug=" + this.state.subcateSlug + "&product_is_combo=Yes"
    axios.get(apiUrlCtrng + "catering/package_list?" + params).then((res) => {
      if (res.data.status === "ok") {
        this.displayPackage(res.data.result_set, res.data.common)
      }
    })
  }

  displayPackage(packageList, imagePath, isCat) {
    let displaypackage = []
    if (packageList.length > 0) {
      displaypackage = packageList.map((item, index) => {
        let slug = "/catering/" + this.state.cateSlug + "/" + this.state.subcateSlug + "/" + item.product_slug
        let thumbnail = !!item.product_thumbnail && !!item.product_thumbnail ? imagePath.image_source + "/" + item.product_thumbnail : productNoImg
        if (isCat) {
          slug = "/catering/" + item.pro_cate_slug + "/" + (item.subcategory?.length ? item.subcategory[0].pro_subcate_slug : "")
          thumbnail = !!item.pro_cate_image && !!item.pro_cate_image ? imagePath.cat_image_source + "/" + item.pro_cate_image : productNoImg
        }

        return {
          ...item,
          slug: slug,
          imageSrc: thumbnail,
          price: item.product_price || null,
          product_name: item.product_name || item.pro_cate_name || "",
          product_short_description: item.product_short_description || item.pro_cate_short_description || "",
        }
      })
    }
    console.log(displaypackage)
    this.setState({ displaypackage })
  }

  render() {
    return (
      <>
        <Header />
        <section className="catering-category-banner catering-category-banner--list">
          <div className="catering-banner-inner">
            <h3 className="catering-service">Menu Packages</h3>
            <p className="catering-choose-package">Choose from our package menus to suit your budget and celebration!</p>
          </div>
        </section>
        <div className="product_container">
          <h2 className="catering-package-choose-header">Choose 1 (or more) packages!</h2>

          <section className="catering-subcategories">
            <div className="container">
              <ul className="catering-subcategories__list">
                {this.state.displaypackage.map((item, index) => (
                  <li key={index} className="catering-subcategories__item">
                    <a href={item.slug}>
                      <div className="catering-subcategories__inner">
                        <div className="catering-subcategories__image">
                          <img src={item.imageSrc} />
                        </div>
                        <div className="catering-subcategories__desc">
                          <h5>{!!item.product_alias ? stripslashes(item.product_alias) : stripslashes(item.product_name || "")}</h5>
                          {/* {item.price && <p>From ${item.price} / pax</p>} */}
                          <p>{!!item.product_short_description ? Parser(item.product_short_description) : ""}</p>
                          <button className="catering-subcategories__order-now">Order Now</button>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <h1 className="caterer-for-you">Customize your package!</h1>
            <p className="catering-desc">Reach out today and find out how to customize your own package!</p>
            <div className="catering-link-wrapper">
              <Link to={"/contact-us"} className="catering-package-list-link">
                Find out more
              </Link>
            </div>
          </section>
        </div>
        <Footer {...this.props} />
      </>
    )
  }
}

const mapStateTopProps = (state) => {
  return {
    categorylist: state.categorylist,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST })
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(PackageList)
