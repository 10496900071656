import React, { Component } from "react";
import cookie from "react-cookies";
import { deliveryId, pickupId } from "../Helpers/Config";

class Refpage extends Component {
  constructor(props) {
    super(props);

    let slugtext =
      typeof this.props.match.params.slugtext !== "undefined"
        ? this.props.match.params.slugtext
        : "";

    if (slugtext === "gotoproduct") {
      this.props.history.push("/products");
    } else {
      if (slugtext === "delivery") {
        cookie.save("triggerAvlPop", deliveryId, { path: "/" });
      } else if (slugtext === "pickup") {
        cookie.save("triggerAvlPop", pickupId, { path: "/" });
      } else {
        cookie.remove("triggerAvlPop", { path: "/" });
      }

      this.props.history.push("/");
    }
  }

  render() {
    return <div></div>;
  }
}

export default Refpage;
