/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CATERINGCORDER, SET_CATERINGCORDER } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCateringOrder = function* () {
  yield takeEvery(GET_CATERINGCORDER, workerGetCateringOrder);
};

function* workerGetCateringOrder({ cateringparams }) {
  try {
    const uri =
      apiUrl +
      "reports/order_history?order_status=C&app_id=" +
      appId +
      cateringparams;
    console.log(uri, "uriuri");
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CATERINGCORDER, value: resultArr });
  } catch {
    console.log("Get Current order Failed");
  }
}
